import React, { useState , useRef} from 'react';
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Typography,
  styled,
  Button,
} from '@mui/material';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { primaryColor } from '../../theme/index';
import emailjs from '@emailjs/browser';

// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';

export const ActionButton = styled(MenuItem)(() => ({
  display: 'inline-block',
  paddingRight: '20px',
  paddingLeft: '10px',
  borderBottom: 'none',
  color: 'black',
  background: `${primaryColor} !important`,
  padding: '10px 30px',
  fontWeight: 700,
  borderRadius: '5px',
}));

const LandingPage: React.FC = () => {
  const initialValues = {
    email: '',
    phone: '',
    message: '',
    industry: '',
    solution: '',
  };

  const [values, setValues] = useState(initialValues);
  const [error, setError] = useState('');
  const form = useRef<HTMLFormElement>(null);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!Object.values(values).every((x) => x)) {
      setError('Please fill in all the field before submitting.');
      return;
    }
    setError('');

  // Create a hidden form element
  const hiddenForm = document.createElement('form');
  hiddenForm.style.display = 'none';

  // Create an input field to hold the message content
  const messageInput = document.createElement('input');
  messageInput.type = 'hidden';
  messageInput.name = 'message';
  messageInput.value = `Email: ${values.email}\nPhone: ${values.phone}\nIndustry: ${values.industry}\nHe/She needs packaging Solution: ${values.solution}\nMessage: ${values.message} `;

  // Append the input field to the form
  hiddenForm.appendChild(messageInput);

  // Append the form to the document body
  document.body.appendChild(hiddenForm);
    emailjs
      .sendForm('service_fc614nt', 'template_bi1kbev', hiddenForm, {
        publicKey: '0skenTWz5H7lFY0hO',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log(error);
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <Box sx={{ margin: '45px 0px' }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            style={{
              fontWeight: 'bold',
              borderBottom: '2px solid lightgrey',
              marginBottom: '17px',
            }}
            className="contact-page-title"
          >
            Contact
          </Typography>
        </Grid>
      </Grid>
      <Grid container rowSpacing={{ xs: 3 }} columnSpacing={{ md: 6 }}>
        <Grid item xs={12} md={7}>
          <form ref={form} onSubmit={submitHandler}>
          <Box
            sx={{
              background: '#f4f4f4',
              padding: '20px',
              paddingBottom: '40px',
              borderRadius: '5px',
            }}
          >
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <Typography
              variant="h6"
              sx={{
                marginBottom: '15px',
                fontWeight: 'bold',
                fontSize: '1.1rem',
              }}
            >
              Send A Message
            </Typography>
            <FormControl fullWidth>
              <OutlinedInput
                sx={{
                  marginBottom: '15px',
                  fontSize: '0.75rem',
                  background: 'white',
                }}
                placeholder="Email"
                value={values?.email}
                name="email"
                onChange={handleInputChange}
              />
              <OutlinedInput
                sx={{
                  marginBottom: '15px',
                  fontSize: '0.75rem',
                  background: 'white',
                }}
                placeholder="Phone-no with country code"
                value={values?.phone}
                name="phone"
                onChange={handleInputChange}
              />
              <OutlinedInput
                sx={{
                  marginBottom: '15px',
                  fontSize: '0.75rem',
                  background: 'white',
                }}
                placeholder="Your Industry"
                value={values?.industry}
                name="industry"
                onChange={handleInputChange}
              />
              <OutlinedInput
                sx={{
                  marginBottom: '15px',
                  fontSize: '0.75rem',
                  background: 'white',
                }}
                placeholder="Do you need packaging solution"
                value={values?.solution}
                name="solution"
                onChange={handleInputChange}
              />
              <TextareaAutosize
                minRows={10}
                style={{
                  padding: '10px',
                  borderRadius: '5px',
                  marginBottom: '10px',
                  maxWidth: '100%',
                  width: 'unset',
                }}
                placeholder="Type your enquiry here.."
                value={values?.message}
                name="message"
                onChange={handleInputChange}
              />
            </FormControl>
            <Button
              type="submit"
              sx={{
                marginTop: '10px',
                background: primaryColor,
                color: 'black',
              }}
            >
              {' '}
              Submit{' '}
            </Button>
          </Box>
                      
          </form>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box className="contact-right-container">
            <Typography variant="h6" className="contact-main-heading">
              {' '}
              Give Us A Call{' '}
            </Typography>
            <Typography
              variant="body2"
              className="contact-small-description"
              style={{ marginBottom: '15px' }}
            >
              Monday - Saturday: 9AM - 5PM EST{' '}
            </Typography>
            <Box
              sx={{
                marginBottom: '15px',
              }}
            >
              <Typography variant="body2" className="contact-small-heading">
                {' '}
                Address:{' '}
              </Typography>
              <Typography variant="body2" className="contact-small-description">
                Production Press: 19-A Abbott Road opposite dunya news head office <br/>
                Marketing Place: Manhattan, New York, USA
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                marginBottom: '15px',
              }}
            >
              <Typography variant="body2" className="contact-small-heading">
                {' '}
                Call: {' '}
              </Typography>
              <Typography variant="body2" className="contact-small-description">
              +1 (350) 201-3512
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                marginBottom: '15px',
              }}
            >
              <Typography variant="body2" className="contact-small-heading">
                {' '}
                Whatsapp: {' '}
              </Typography>
              <Typography variant="body2" className="contact-small-description">
              +1 (350) 201-3512
              </Typography>
            </Box>
            <Box
              sx={{
                marginBottom: '15px',
              }}
            >
              <Typography variant="body2" className="contact-small-heading">
                {' '}
                Email us anytime:{' '}
              </Typography>
              <Typography variant="body2" className="contact-small-description">
                contact@evolvepackages.com
              </Typography>
            </Box>
            <Box
              sx={{
                marginBottom: '15px',
              }}
            >
              <Typography variant="body2" className="contact-small-heading">
                {' '}
                More questions or concerns:{' '}
              </Typography>
              <Typography variant="body2" className="contact-small-description">
                Freely ask us on whatsapp or email
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LandingPage;
