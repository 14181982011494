
import LandingPage from '../../pages/LandingPage';
import Recommendation from '../../pages/Recommendation/Recommendation.container';
import ContactUs from '../../pages/ContactUs/ContactUs.container';
import AboutUsPage from '../../pages/AboutUsPage';
import Press from '../../pages/Press';
import PortfolioPageContainer from '../../pages/PortfolioPage/PortfolioPage.container';
import DetailContainer from '../../pages/detail/Detail.container';

export const protectedBackendRoutes = [
];

export const publicRoutes = [
  {
    path: '/',
    element: LandingPage,
  },
  {
    path: '/recommendation',
    element: Recommendation,
  },
  {
    path: '/contact',
    element: ContactUs,
  },
  {
    path: '/products',
    element: AboutUsPage,
  },
  {
    path: '/press',
    element: Press,
  },
  {
    path: '/portfolio',
    element: PortfolioPageContainer,
  },
  {
    path: '/detail-page/:id',
    element: DetailContainer,
  },
  {
    path: '/quotes',
    element: AboutUsPage,
  },
];
