import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import {
  OutlinedInput,
} from '@mui/material';
import emailjs from '@emailjs/browser';

const Description = ({ title, description }) => {
  const initialValues = {
    phone: '',
  };

  const [values, setValues] = React.useState(initialValues);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const [bulkOrders, setBulkOrder] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [itemOpen, setItemOpen] = React.useState(false);
  const [item, setItem] = React.useState('');

  const handleChange = (event) => {
    setBulkOrder(event.target.value);
  };

  const handleItemChange = (event) => {
    setItem(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleItemOpen = () => {
    setItemOpen(true);
  };

  const handleItemClose = () => {
    setItemOpen(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!Object.values(values).every((x) => x)) {
      return;
    }

  // Create a hidden form element
  const hiddenForm = document.createElement('form');
  hiddenForm.style.display = 'none';

  // Create an input field to hold the message content
  const messageInput = document.createElement('input');
  messageInput.type = 'hidden';
  messageInput.name = 'message';
  messageInput.value = `Bulk: ${bulkOrders}\nPhoneNo: ${values.phone}\nItem: ${item}`;

  // Append the input field to the form
  hiddenForm.appendChild(messageInput);

  // Append the form to the document body
  document.body.appendChild(hiddenForm);
    emailjs
      .sendForm('service_fc614nt', 'template_bi1kbev', hiddenForm, {
        publicKey: '0skenTWz5H7lFY0hO',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <section className="description">
      <p className="pre">evolve packages</p>
      <h1>{title}</h1>
      <p className="desc">
        {description}
      </p>
      <div>
      <Button sx={{ display: 'block', mt: 2, color: 'rgb(0 0 0);' }} onClick={handleOpen}>
      <p className="pre">Looking for Bulk Orders</p>
      </Button>
      <FormControl sx={{ m: 1, minWidth: 140 }}>
        <InputLabel id="demo-controlled-open-select-label">Bulk Orders</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={bulkOrders}
          label="Bulk Orders Selection"
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'Yes'}>Yes</MenuItem>
          <MenuItem value={'No'}>No</MenuItem>
        </Select>
      </FormControl>
    </div>
    <div>
      <Button sx={{ display: 'block', mt: 2, color: 'rgb(0 0 0);' }} onClick={handleItemOpen}>
      <p className="pre">Select Item for packaging</p>
      </Button>
      <FormControl sx={{ m: 1, minWidth: 140 }}>
        <InputLabel id="demo-controlled-open-select-label">Select Item</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={itemOpen}
          onClose={handleItemClose}
          onOpen={handleItemOpen}
          value={item}
          label="Select Item"
          onChange={handleItemChange}
        >
          <MenuItem value={"Tags"}>Tags</MenuItem>
          <MenuItem value={"Inlays"}>Inlays</MenuItem>
          <MenuItem value={"Brouchers"}>Brouchers</MenuItem>
          <MenuItem value={"Shoe Boxes"}>Shoe Boxes</MenuItem>
          <MenuItem value={"Rigid Boxes"}>Rigid Boxes</MenuItem>
          <MenuItem value={"Skin Care Boxes"}>Skin Care Boxes</MenuItem>
          <MenuItem value={"Skin Care Boxes"}>Fancy Bag Packaging</MenuItem>
          <MenuItem value={"Printed Paper Bags"}>Printed Paper Bags</MenuItem>
          <MenuItem value={"Printed Card Bags"}>Printed Card Bags</MenuItem>
          <MenuItem value={"Customized Packaging"}>Customized Packaging</MenuItem>
        </Select>
      </FormControl>
    </div>
    <div>
      <p className="pre" style={{marginBottom: '10px'}}>Please Enter Phone No With Country Code</p>
      <p style={{marginBottom: '10px'}}>Our represntative will contact you on this number</p>
    <OutlinedInput
                sx={{
                  marginBottom: '15px',
                  fontSize: '0.75rem',
                  background: 'white',
                }}
                placeholder="Phone-no with country code"
                value={values?.phone}
                name="phone"
                onChange={handleInputChange}
              />
    </div>
    <div>
      <p className="pre">Note: Orders can take some time to prepare and 2 or 3 day reserve for delievery or dipatch, We will require full list of information regarding your product design and specs at email or on chat before finalizing. You can also contact us on chat directly</p>
    </div>
      <div className="buttons">
        <button
          className="add-to-cart"
          onClick={submitHandler}
        >
          sent for quotation
        </button>
      </div>
    </section>
  );
};

export default Description;
