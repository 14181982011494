import { Box } from '@mui/material';
import React from 'react';
import CoreValues from './CoreValues';
const AboutUsPage: React.FC = () => {
  return (
    <Box className="about-us-main-container">

      <CoreValues />

    </Box>
  );
};

export default AboutUsPage;
