import React from 'react';
import { Box, Grid, MenuItem, Paper, styled, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate, Link } from 'react-router-dom';
import { ashWhiteColor, mediumGrey, primaryColor, whiteBorderColor, whiteColor } from '../../theme';

export const ActionButton = styled(MenuItem)(() => ({
  display: 'inline-block',
  paddingRight: '20px',
  paddingLeft: '10px',
  borderBottom: 'none',
  color: 'black',
  background: `${primaryColor} !important`,
  padding: '10px 30px',
  fontWeight: 700,
  borderRadius: '5px',
}));

const LandingPage: React.FC = () => {
  // const {medicines, getMedicines} = props;
  // const [items, setItems] = useState([]);
  const navigate = useNavigate();

  const items = [
    { id: '0', name: 'Hand Bags', color: '#d2c5b5', image: '/paper-bag-removebg-preview.png' },
    { id: '1', name: 'Tags', color: '#efe8df', image: '/tags.png' },
    {
      id: '2',
      name: 'Fancy Bag Packaging',
      color: '#ddded9',
      image: '/fancy-bag.png',
    },
    { id: '3', name: 'Brouchers', color: '#e9ba9e', image: '/broucher.png' },
    { id: '4', name: 'Shoe Boxes', color: '#efe8df', image: '/shoe-box.png' },
    { id: '7', name: 'Shampo Boxes', color: '#ddded9', image: '/shampoo.png' },
  ];

  function createData(
    name: string,
    col1: string,
    col2: string,
  ) {
    return { name, col1, col2 };
  }

  const rows = [
    createData(
      'Sampling',
      'Free just shipping charges',
      'Will charge both'
    ),
    createData(
      'Quality',
      'Excellent',
      'Good'
    ),
    createData(
      'Flexibility Packaging',
      'Yes',
      'Maybe',
    ),
    createData('Locally Registered', 'Yes', 'Maybe'),
    createData(
      'Cost',
      'Affordable',
      'High',
    ),
    createData(
      'Shipping',
      'DHL or FedEx',
      'Who knows',
    ),
    createData(
      'Shipping Time',
      '3 days after making',
      '15 days',
    ),
  ];


  return (
    <div className="child">
      <Grid
        container
        rowSpacing={{ xs: 3, md: 0 }}
        display="flex"
        flexDirection={'row'}
        sx={{ padding: '60px 0px' }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'start',
          }}
        >
          <h4 style={{ paddingBottom: '8px' }}>
            Your #1 Packaging Solution Provider
          </h4>
          <h1 style={{ paddingBottom: '10px' }}>
            Get Quotations For{' '}
            <span style={{ color: primaryColor }}>Packaging</span> Today!
          </h1>
          <p style={{ paddingBottom: '10px', width: '80%', color: '#a0a2a7' }}>
          Elevating handbags, brouchers, fancy bags packaging, entique product with artisanal packaging. Experience luxury, creativity, and global excellence with our bespoke designs, providing samples first for a touch of perfection.
          </p>
          <ActionButton onClick={() => navigate('/contact')}>
            Get Quotation
          </ActionButton>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src={'/landing-page-box1.jpeg'}
            alt={'Hero-Image'}
            loading="lazy"
            height={'90%'}
            width={'100%'}
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          width: '100vw',
          background: ashWhiteColor,
          position: 'relative',
          marginLeft: 'calc((100% - 100vw) / 2)',
          padding: '60px calc(max((100vw - min(560px, 100%)) / 5, 20px))',
        }}
      >
        <Grid item xs={12} md={6}>
          <h4 style={{ paddingBottom: '8px' }}>What we provide</h4>
          <h1 style={{ paddingBottom: '10px' }}>
            Why should you pick EvolvePack for packaging needs?
          </h1>
          <Grid display={'flex'} flexDirection={'column'} sx={{ width: '80%' }}>
            <div
              style={{
                padding: '15px',
                border: '1px solid #c0c1c5',
                marginBottom: '10px',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <img
                src={'/success.png'}
                alt={'success'}
                loading="lazy"
                height={'25px'}
                style={{ paddingRight: '4px' }}
              />
              <div>Better quality with each order placed</div>
            </div>
            <div
              style={{
                padding: '15px',
                border: '1px solid #c0c1c5',
                marginBottom: '10px',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <img
                src={'/success.png'}
                alt={'success'}
                loading="lazy"
                height={'25px'}
                style={{ paddingRight: '4px' }}
              />
              <div>Lower production cost and shipping worldwide with DHL</div>
            </div>
            <div
              style={{
                padding: '15px',
                border: '1px solid #c0c1c5',
                marginBottom: '10px',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <img
                src={'/success.png'}
                alt={'success'}
                loading="lazy"
                height={'25px'}
                style={{ paddingRight: '4px' }}
              />
              <div>Free samples check before actual order</div>
            </div>
            <div
              style={{
                padding: '15px',
                border: '1px solid #c0c1c5',
                marginBottom: '10px',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <img
                src={'/success.png'}
                alt={'success'}
                loading="lazy"
                height={'25px'}
                style={{ paddingRight: '4px' }}
              />
              <div>Registered with <a href='https://www.secp.gov.pk/'>SECP</a></div>
            </div>
            <div
              style={{
                padding: '15px',
                border: '1px solid #c0c1c5',
                marginBottom: '10px',
                borderRadius: '5px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <img
                src={'/success.png'}
                alt={'success'}
                loading="lazy"
                height={'25px'}
                style={{ paddingRight: '4px' }}
              />
              <div>Already in bussiness for more than 10 years</div>
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <img
            className="sm-width-100"
            src={'/landing-page-box2.jpg'}
            alt={'Hero-Image'}
            loading="lazy"
            height={'90%'}
            width={'80%'}
          />
        </Grid>
      </Grid>
      <Grid
        container
        display="flex"
        flexDirection={'row'}
        sx={{ padding: '60px 0px' }}
      >
        <Grid item xs={12} md={6}>
          <img
            className="sm-width-100"
            src={'/cost.jpeg'}
            alt={'Hero-Image'}
            loading="lazy"
            height={'100%'}
            width={'80%'}
            style={{ borderRadius: '20px' }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <h4 style={{ paddingBottom: '8px' }}>Benefits of choosing us?</h4>
          <h1 style={{ paddingBottom: '10px' }}>Affordable Costs</h1>
          <p style={{ paddingBottom: '10px' }}>
          we take pride in our commitment to affordability, offering handbags and packaging solutions that balance cost-effectiveness with international standards. Operating locally, we utilize state-of-the-art machinery to create high-quality products that rival global competitors. Our dedication to precision and style is evident in every piece we produce. By providing accessible elegance, we ensure that luxury is not limited by price. Join us in experiencing the perfect blend of affordability, craftsmanship, and global quality at EvolvePacks.
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          width: '100vw',
          background: ashWhiteColor,
          position: 'relative',
          marginLeft: 'calc((100% - 100vw) / 2)',
          padding: '60px calc(max((100vw - min(560px, 100%)) / 5, 20px))',
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <h2 style={{ paddingBottom: '10px' }}>Packaging Flexibility</h2>
          <p style={{ paddingBottom: '10px', width: '80%' }}>
          At EvolvePacks, we redefine packaging flexibility, tailoring each order to match your unique product designs. Our commitment to customization knows no bounds – whether you have a specific concept or intricate details, we bring your vision to life. With an unwavering dedication to craftsmanship, our skilled team ensures that every packaging solution aligns seamlessly with your brand identity. At EvolvePacks, we dont just create packages we craft an experience tailored to your products essence. Embrace the freedom of design with us, where your imagination meets our expertise in a personalized packaging journey.
          </p>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            className="sm-width-100"
            src={'/flexible-p.webp'}
            alt={'Hero-Image'}
            loading="lazy"
            height={'100%'}
            width={'80%'}
          />
        </Grid>
      </Grid>
      <Grid
        container
        display="flex"
        flexDirection={'row'}
        sx={{ padding: '60px 0px' }}
      >
        <Grid item xs={12} md={6}>
          <img
            className="sm-width-100"
            src={'/quality.jpeg'}
            alt={'hair-man'}
            loading="lazy"
            height={'90%'}
            width={'80%'}
            style={{ borderRadius: '20px' }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <h1 style={{ paddingBottom: '5px' }}>Why Better Quality?</h1>
          <p style={{ paddingBottom: '10px' }}>
          At EvolvePacks, our commitment to superior quality is rooted in our unique approach. Producing locally and serving globally, we leverage state-of-the-art machinery and benefit from lower labor charges, allowing us to uphold impeccable standards without compromise. Our dedication to excellence transcends borders, ensuring that every product reflects the precision, durability, and sophistication synonymous with EvolvePacks. Choose us for unparalleled quality that seamlessly combines affordability, global reach, and cutting-edge craftsmanship.
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        display="flex"
        flexDirection={'row'}
        sx={{ padding: '60px 0px', paddingTop: '0px' }}
      >
        <Grid item xs={6} sm={3} md={3}>
          <Grid
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <img
              src={'/ED-Medication.png'}
              alt={'hair-man'}
              loading="lazy"
              style={{ borderRadius: '20px' }}
            />
          </Grid>
          <Grid
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ paddingBottom: '10px' }}
          >
            No worry with us
          </Grid>
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <Grid
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <img
              src={'/Anti-Depressant.png'}
              alt={'hair-man'}
              loading="lazy"
              style={{ borderRadius: '20px' }}
            />
          </Grid>
          <Grid
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ paddingBottom: '10px' }}
          >
            Solution Providers
          </Grid>
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <Grid
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <img
              src={'/Sleep-Aids.png'}
              alt={'hair-man'}
              loading="lazy"
              style={{ borderRadius: '20px' }}
            />
          </Grid>
          <Grid
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ paddingBottom: '10px' }}
          >
            Free Sampling
          </Grid>
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <Grid
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <img
              src={'/Hair-Loss.png'}
              alt={'hair-man'}
              loading="lazy"
              style={{ borderRadius: '20px' }}
            />
          </Grid>
          <Grid
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ paddingBottom: '10px' }}
          >
            Long Term Commitment
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12}>
          <h4>Our Products</h4>
          <h1>Wider Range of Packaging Products We Provide</h1>
          <Typography sx={{ width: '70%' }}>
            Choose wider range of solutions below. Select any provided solution or provide us your need we will take care of rest for you from designing to packaging to dispatching.
          </Typography>
        </Grid>
        <div style={{ width: '100%', overflowY: 'scroll'}}>
          <Grid
            container
            spacing={2}
            sx={{ minWidth: 1200, padding: '30px 5px' }}
          >
            {items?.map((item: any, index: any) => (
              <Grid key={index} item xs={2} sx={{ cursor: 'pointer' }}>
                <Link to={`/detail-page/${item.id}`} style={{ textDecoration: 'none' }}>
                <Paper
                  elevation={0}
                  sx={{
                    padding: '20px 10px',
                    backgroundColor: `${item.color}`,
                    height: '150px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    borderRadius: '20px',
                  }}
                >
                  <Grid sx={{ fontWeight: 600 }}>{item.name}</Grid>
                  <Grid container alignItems="flex-end">
                    <Grid item xs={4}>
                      <Box
                        sx={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '50%',
                          width: '48px',
                          height: '48px',
                          backgroundColor: `${whiteColor}`,
                        }}
                      >
                        <ArrowForwardIcon />
                      </Box>
                    </Grid>
                    <Grid item xs={8}>
                      <img
                        src={item.image}
                        alt={'viagra'}
                        loading="lazy"
                        height={'120px'}
                        width={'100%'}
                        style={{ transform: 'scale(1.2)', overflow: 'hidden' }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
                </Link>
              </Grid>
            ))}
          </Grid>
        </div>
      </Grid>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ background: ashWhiteColor, margin: '70px 0px', borderRadius: '20px' }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <h3 style={{ margin: 0, textAlign: 'center' }}>
                        Companies
                </h3>
              </TableCell>
              <TableCell
                sx={{
                  background: primaryColor,
                }}
              >
                    <h3 style={{ margin: 0, textAlign: 'center' }}>
                        EvolvePacks
                </h3>
              </TableCell>
              <TableCell sx={{  }}>
                <h3 style={{ margin: 0, textAlign: 'center' }}>
                  Traditional Packaging Companies
                </h3>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  '&:last-child td': {
                    borderBottom: 0,
                  },
                  '&:last-child th': {
                    border: 0,
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    textAlign: 'center',
                    fontWeight: 600,
                    padding: '30px !important',
                  }}
                >
                  {row.name}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: 'center',
                    borderRight: `1px solid ${whiteBorderColor}`,
                    background: primaryColor,
                    fontWeight: 600,
                    fontSize: '17px',
                  }}
                >
                  {row.col1}
                </TableCell>
                <TableCell
                  sx={{ textAlign: 'center' }}
                >
                  {row.col2}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid
        container
        sx={{
          width: '100vw',
          background: ashWhiteColor,
          position: 'relative',
          marginLeft: 'calc((100% - 100vw) / 2)',
          padding: '60px calc(max((100vw - min(560px, 100%)) / 5, 20px))',
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
        >
          <h4 style={{ marginBottom: 0 }}>Process</h4>
          <h2 style={{ marginBottom: 0 }}>How Packaging Process Works</h2>
          <p style={{ paddingBottom: '10px', width: '70%' }}>
            With the packaging options and flexibilty available, EvolvePacks gets
            you the help you need - fast, safe and affordable.
          </p>
        </Grid>
        <Grid
          container
          columnSpacing={{ xs: 3, md: 8 }}
          rowSpacing={{ xs: 8, md: 3 }}
        >
          <Grid
            className="process-mini-container process-mini-container-first-arrow"
            item
            xs={12}
            md={4}
          >
            <Box display={'flex'} justifyContent={'center'}>
              <img
                src={'/treatment-plan.png'}
                alt={'treatment plan'}
                loading="lazy"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            </Box>
            <Box sx={{ height: '50px' }}>
              <Typography
                style={{
                  textAlign: 'center',
                  fontWeight: 600,
                  paddingTop: '20px',
                }}
              >
                Finalize packaging designs
              </Typography>
            </Box>
            <Box sx={{ paddingTop: '20px' }}>
              <Typography style={{ textAlign: 'center', color: `${mediumGrey}` }}>
                After you select and send us the packaging designs or you rest this to us 
                we will provide sampling according to that design of your package.
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            className="process-mini-container process-mini-container-second-arrow"
            xs={12}
            md={4}
          >
            <Box display={'flex'} justifyContent={'center'}>
              <img
                src={'/place-order.png'}
                alt={'place order'}
                loading="lazy"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            </Box>
            <Box sx={{ height: '50px' }}>
              <Typography
                style={{
                  textAlign: 'center',
                  fontWeight: 600,
                  paddingTop: '20px',
                }}
              >
                Prepare Order
              </Typography>
            </Box>
            <Box sx={{ paddingTop: '20px' }}>
              <Typography style={{ textAlign: 'center', color: `${mediumGrey}` }}>
                We will prepare approved sample order and start making your order through our
                in house machinery from designing, printing and cutting and packaging.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box display={'flex'} justifyContent={'center'}>
              <img
                src={'/health-survey.png'}
                alt={'health survey'}
                loading="lazy"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            </Box>
            <Box sx={{ height: '50px' }}>
              <Typography
                style={{
                  textAlign: 'center',
                  fontWeight: 600,
                  paddingTop: '20px',
                }}
              >
                Shipping
              </Typography>
            </Box>
            <Box sx={{ paddingTop: '20px' }}>
              <Typography style={{ textAlign: 'center', color: `${mediumGrey}` }}>
                After every things ready we will send your the order pictures to your
                team and then dispatch item from our local production press. From there 
                you or our team will pick or dispatch you via DHL or Fedex
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          display={'flex'}
          justifyContent={'center'}
          sx={{ width: '100%', paddingTop: '50px' }}
        >
          <ActionButton onClick={() => navigate('/contact')}>
            Contact Us
          </ActionButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default LandingPage;
