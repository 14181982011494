import { Box } from '@mui/material';
import React from 'react';
import PressPage from './press-page';
const AboutUsPage: React.FC = () => {
  return (
    <Box className="about-us-main-container">
      <PressPage/>
    </Box>
  );
};

export default AboutUsPage;
