import { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthRoutes from './routes/routes/AuthRoutes';
import PublicRoutes from './routes/routes/PublicRoutes';
import './App.css';
import Layout from './layout/main';
import './FloatingWhatsAppIcon.css';

const App: FC = () => {
  const openWhatsAppChat = () => {
    // Replace 'your_number' with your actual WhatsApp number
    window.open('https://wa.me/13502013512', '_blank');
  };
  return (
    <Router>
      <Layout>
        <PublicRoutes />
        <AuthRoutes />
        <div className="floating-whatsapp-icon" onClick={openWhatsAppChat}>
      <img src={'/whatsapp.png'} alt="WhatsApp Icon" />
    </div>
      </Layout>
    </Router>
  );
};

export default App;
