import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { whiteColor } from '../../theme';

const coreValuesArray = [
  {
    title: 'Skinly Client (Skin-Care packaging)',
    image: '/p1.jpeg',
  },
  {
    title: 'Qalamkar Client (Inlays Prints)',
    image: '/qalamkar.png',
  },
  {
    title: 'Sartor Client (Rigid Box Packaging)',
    image: '/sartor.png',
  },
  {
    title: 'AGP Client',
    image: '/agp.png',
  },
  {
    title: 'CAT client (Tags)',
    image: '/cat.png',
  },
  {
    title: 'Saphire Client (Rigid Box Packaging)',
    image: '/saphire.png',
  },
  {
    title: 'Skinly Client (Customized Box Packaging)',
    image: '/rb.png',
  },
  {
    title: 'Theracran Client (Customized Box Packaging)',
    image: '/tr.png',
  },
  {
    title: 'Customized Packaging',
    image: '/all.png',
  },
];

const CoreValues: React.FC = () => {
  return (
    <Fragment>
      <Grid
        container
        spacing={1}
        sx={{
          padding: '0 calc((100vw - min(560px, 100%)) / 5)',
          paddingTop: '30px',
          marginLeft: '0px',
        }}
      >
        <Grid item xs={12}>
          <div>
            <p> What we deliver</p>
            <h1>Customized Packaging Delivered To Clients</h1>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{
          padding: '0 calc((100vw - min(560px, 100%)) / 5)',
          paddingTop: '30px',
          paddingBottom: '40px',
          marginLeft: '0px',
        }}
      >
        {coreValuesArray.map((value, index) => (
          <Grid key={index + 1} item xs={12} sm={6} md={4} paddingBottom={4}>
          <Card sx={{ maxWidth: '100%', boxShadow: 'none', p: 0 }}>
            <CardMedia
              component="img"
              height="600px"
              image={value.image}
              alt="green iguana"
              sx={{
                width: '100%', // Set the width to 100%
                borderRadius: 2,
                objectFit: 'cover', // Maintain aspect ratio and fill the container
              }}
            />
            <CardContent
              sx={{
                position: 'relative',
                paddingTop: '45px',
                paddingLeft: '30px',
                background: whiteColor,
                marginTop: '-65px',
                zIndex: 2,
              }}
            >
              <Typography
                gutterBottom
                variant="button"
                className="core-value-card-counter"
                component="div"
              >
                <b>
                  {index < 8 && '0'}
                  {index + 1}
                </b>
              </Typography>
              <Typography gutterBottom variant="h6" component="div">
                {value.title}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};

export default CoreValues;
