import { Box } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom'; // Import useParams hook
import Gallery from "../Components/Gallery";
import Description from "../Components/Description";
import MobileGallery from "../Components/MobileGallery";

const items = [
  {
    id: '0',
    image: "/paper-bag-removebg-preview.png",
    title: "Printed Paper Bags",
    desc: "These paper hands bags will be used for packing your product in economical way, perfect for low cost packaging",
  },
  {
    id: '1',
    image: "/tags.png",
    title: "Tags",
    desc: "These tags can be used with cloths or anythings according to your need for better branding",
  },
  {
    id: '2',
    image: "/fancy-bag.png",
    title: "Fancy Bag Packaging",
    desc: "These fancy bags will be used for packing your product in economical way, perfect for low cost packaging",
  },
  {
    id: '3',
    image: "/broucher.png",
    title: "Brouchers",
    desc: "These brouchers can be used for marketing purposes of your bussiness",
  },
  {
    id: '4',
    image: "/shoe-box.png",
    title: "Show Box",
    desc: "These show box will be used for packing your product.",
  },
  {
    id: '5',
    image: "/shampoo.png",
    title: "Shampoo Box",
    desc: "These shampoo box will be used for packing your product.",
  },
  {
    id: '6',
    image: "/shampoo.png",
    title: "Skin Care Boxes",
    desc: "These skin care box will be used for packing your product.",
  },
  {
    id: '7',
    image: "/shampoo.png",
    title: "Customized Boxes",
    desc: "These customized boxes will be used for packing your product.",
  }
];

const AboutUsPage: React.FC = () => {
  const { id } = useParams(); // Fetch the id from the route params

  const isIdInList = (id: any, itemList: any) => {
    return itemList.some((item: any) => item.id === id);
  };
  
  return (
    <Box className="about-us-main-container">
      {(isIdInList(id, items)) ?
        <section className="core">
            <Gallery prodImag={(id !== undefined && !isNaN(parseInt(id))) ? items[parseInt(id)].image : ''}/>
            <MobileGallery prodImage={(id !== undefined && !isNaN(parseInt(id))) ? items[parseInt(id)].image : ''}/>
            <Description
              title={(id !== undefined && !isNaN(parseInt(id))) ? items[parseInt(id)].title : ''}
              description={(id !== undefined && !isNaN(parseInt(id))) ? items[parseInt(id)].desc : ''}
            />
        </section> :
        ''
      }
    </Box>
  );
};

export default AboutUsPage;
