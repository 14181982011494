import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';
import { whiteColor } from '../../theme';

const coreValuesArray = [
  {
    title: 'Heidelberg 5 Color (Production Dept)',
    description: 'Machine for printing 4 color and shine on packaging product',
    image: 'hed-3.mp4',
  },
  {
    title: 'Heidelberg 5 Color (Production Dept)',
    description: 'Machine for printing 4 color and shine on packaging product',
    image: 'hed-1.jpeg',
  },
  {
    title: 'Heidelberg 5 Color (Production Dept)',
    description: 'Machine for printing 4 color and shine color on packaging product',
    image: 'hed-2.jpeg',
  },
  {
    title: 'Solna 125 (Production Dept)',
    description: 'Machine for printing 1 color on your product at a time',
    image: 'solna.jpeg',
  },
  {
    title: 'Paper Cutting (Paper Finalizing Dept)',
    description: 'Initial paper cutting area',
    image: 'paper-cutting.jpeg',
  },
];

const PressPage: React.FC = () => {
  return (
    <Fragment>
      <Grid
        container
        spacing={1}
        sx={{
          padding: '0 calc((100vw - min(560px, 100%)) / 5)',
          paddingTop: '30px',
          marginLeft: '0px',
        }}
      >
        <Grid item xs={12}>
          <div>
            <p> Our Press</p>
            <h1>In House Production Machines</h1>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{
          padding: '0 calc((100vw - min(560px, 100%)) / 5)',
          paddingTop: '30px',
          paddingBottom: '40px',
          marginLeft: '0px',
        }}
      >

        <Grid key={0} item xs={12} sm={6} md={4}>
          <Card sx={{ maxWidth: '100%', boxShadow: 'none', p: 0 }}>
            <video width="100%" height="240" controls>
              <source src={coreValuesArray[0].image} type="video/mp4" />
            </video>
            <CardContent
             sx={{
              position: 'relative',
              paddingTop: '45px',
              paddingLeft: '30px',
              background: whiteColor,
              zIndex: 2,
            }}>
              <Typography
                  gutterBottom
                  variant="button"
                  className="core-value-card-counter"
                  component="div"
                >
                  <b>
                    {'01'}
                  </b>
                </Typography>
              <Typography variant="h6" gutterBottom>
                {coreValuesArray[0].title} - video
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {coreValuesArray[0].description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>


        {coreValuesArray.slice(1).map((value, index) => (
          <Grid key={index + 1} item xs={12} sm={6} md={4} paddingBottom={4}>
            <Card sx={{ maxWidth: '100%', boxShadow: 'none', p: 0 }}>
              <CardMedia
                component="img"
                height="300px"
                image={value.image}
                alt="green iguana"
                sx={{
                  borderRadius: 2,
                }}
              />
              <CardContent
                sx={{
                  position: 'relative',
                  paddingTop: '45px',
                  paddingLeft: '30px',
                  background: whiteColor,
                  marginTop: '-65px',
                  zIndex: 2,
                }}
              >
                <Typography
                  gutterBottom
                  variant="button"
                  className="core-value-card-counter"
                  component="div"
                >
                  <b>
                    {index < 8 && '0'}
                    {index + 1 + 1}
                  </b>
                </Typography>
                <Typography gutterBottom variant="h6" component="div">
                  {value.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {value.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}

        
      </Grid>
    </Fragment>
  );
};

export default PressPage;
