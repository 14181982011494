import { useState, useEffect } from "react";

const Gallery = ({prodImag}) => {
  const [currentImage] = useState(prodImag);
  const [currentPassedImage, setCurrentPassedImage] = useState(prodImag);
  console.log(currentPassedImage);

  useEffect(() => {
    setCurrentPassedImage(currentImage);
  }, [currentImage]);

  return (
    <section className="gallery-holder hide-in-mobile">
      <section className="gallery">
        <div className="image">
          <img src={currentImage} alt="product-1" onClick={() => {}} />
        </div>
      </section>
    </section>
  );
};

export default Gallery;
