import { useState } from "react";


const MobileGallery = ({prodImage}) => {
  const [currentMobileImage] = useState(prodImage);

  return (
    <section className="mobile-gallery hide-in-desktop">
      <img src={currentMobileImage} alt="featured-product" />
    </section>
  );
};

export default MobileGallery;
