/* eslint-env browser */
import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { composeWithDevTools } from 'redux-devtools-extension';
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import './index.css';
import App from './App';
import rootSaga from './store/rootSaga';
import rootReducer from './store/reducer';
import reportWebVitals from './reportWebVitals';
import middleware, { sagaMiddleware } from './middleware';

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
sagaMiddleware.run(rootSaga);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
