import { Card, CardContent, CardMedia, Grid, Typography, MenuItem, styled,  } from '@mui/material';
import React, { Fragment } from 'react';
import { whiteColor } from '../../theme';
import { useNavigate } from 'react-router-dom';

const coreValuesArray = [
  {
    id: '0',
    title: 'Paper Hand Bags',
    description: 'Get customized paper hand bags for your brand',
    image: '/paper-bag-removebg-preview.png',
  },
  {
    id: '1',
    title: 'Printed Tags',
    description: 'Get Customized tags printed on both sides',
    image: '/tags.png',
  },
  {
    id: '2',
    title: 'Fancy Bags Packaging',
    description: 'Get fancy bags and inner with printed and pani strip',
    image: '/fbn.png',
  },
  {
    id: '3',
    title: 'Brouchers',
    description: 'Get your printed brouchers with varnished UV',
    image: '/broucher.png',
  },
  {
    id: '4',
    title: 'Shoe Box Packaging',
    description: 'Get your show box packaging with us, min order 400pc',
    image: '/shoe-box.png',
  },
  {
    id: '7',
    title: 'Customized Box',
    description: 'Can call us for further assistance on any custom packaging',
    image: '/shampoo.png',
  },
];

export const ActionButton = styled(MenuItem)(() => ({
  display: 'inline-block',
  paddingRight: '20px',
  paddingLeft: '10px',
  borderBottom: 'none',
  color: 'black',
  background: `#fec256 !important`,
  padding: '10px 30px',
  fontWeight: 700,
  borderRadius: '5px',
}));

const CoreValues: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <Grid
        container
        spacing={1}
        sx={{
          padding: '0 calc((100vw - min(560px, 100%)) / 5)',
          paddingTop: '30px',
          marginLeft: '0px',
        }}
      >
        <Grid item xs={12}>
          <div>
            <p> What we Offer</p>
            <h1>Our Products & Customized Orders</h1>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{
          padding: '0 calc((100vw - min(560px, 100%)) / 5)',
          paddingTop: '30px',
          paddingBottom: '40px',
          marginLeft: '0px',
        }}
      >
        {coreValuesArray.map((value, index) => (
          <Grid key={index + 1} item xs={12} sm={6} md={4} paddingBottom={4}>
          <Card sx={{ maxWidth: '100%', boxShadow: 'none', p: 0 }}>
            <CardMedia
              component="img"
              height="400px"
              image={value.image}
              alt="green iguana"
              sx={{
                width: '100%', // Set the width to 100%
                borderRadius: 2,
                objectFit: 'cover', // Maintain aspect ratio and fill the container
              }}
            />
            <CardContent
              sx={{
                position: 'relative',
                paddingTop: '45px',
                paddingLeft: '30px',
                background: whiteColor,
                marginTop: '-65px',
                zIndex: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography
                gutterBottom
                variant="button"
                className="core-value-card-counter"
                component="div"
              >
                <b>
                  {index < 8 && '0'}
                  {index + 1}
                </b>
              </Typography>
              <Typography gutterBottom variant="h6" component="div">
                {value.title}
              </Typography>
              <Typography variant="body2" color="text.secondary" pb={2}>
                {value.description}
              </Typography>
              <ActionButton onClick={() => navigate(`/detail-page/${value.id}`)}>
            Get Quotation
          </ActionButton>
            </CardContent>
          </Card>
        </Grid>
        ))}
      </Grid>
    </Fragment>
  );
};

export default CoreValues;
